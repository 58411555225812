<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark" @click.stop="row.toggleDetails"
			class="mr-1 mt-1">
			<em class="fa fa-eye-slash" v-if="row.detailsShowing"></em>
			<em class="fa fa-eye" v-else></em>
		</b-button>

		<b-button v-show="isVisible(['Open'])" size="sm" v-b-tooltip.hover.top="'Edit Maintenance'" variant="warning"
			@click.stop="editMaintenance(row.item)" class="mr-1 mt-1">
			<em class="fa fa-pencil"></em>
		</b-button>

		<b-button v-show="isVisible(['Open', 'In-Repair'])" size="sm" v-b-tooltip.hover.top="'Repair Maintenance'"
			variant="success" @click.stop="repairMaintenance(row.item)" class="mr-1 mt-1">
			<em class="fa fa-wrench"></em>
		</b-button>

		<b-button v-show="isVisible(['In-Repair'])" size="sm" v-b-modal.complete-maintenance
			v-b-tooltip.hover.top="'Complete Maintenance'" variant="success" @click.stop="updateSelMaintenance(row.item)"
			class="mr-1 mt-1">
			<em class="fa fa-check"></em>
		</b-button>

		<b-button v-show="isAllowedToCancel(row.item, ['Open', 'In-Repair'])" size="sm" v-b-modal.cancel-maintenance
			v-b-tooltip.hover.top="'Cancel Maintenance'" variant="danger" @click.stop="updateSelMaintenance(row.item)"
			class="mr-1 mt-1">
			<em class="fa fa-ban"></em>
		</b-button>

		<b-button size="sm" v-b-modal.print-maintenance-summary v-b-tooltip.hover.top="'Print Maintenance Summary'"
			variant="primary" @click.stop="updateSelMaintenance(row.item)" class="mr-1 mt-1">
			<i class="fa fa-print"></i>
		</b-button>
	</div>
</template>

<script>
import EventBus from '@/shared/event-bus';

export default {
	name: 'maintenance-row-actions',
	props: {
		row: {
			type: Object,
			required: true,
		},
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		}
	},
	data() {
		return {
			loggedUserCompany: this.$store.getters.loggedUserCompany,
		};
	},
	computed: {
		status() {
			return this.row && this.row.item.status;
		},
	},
	methods: {
		editMaintenance(item) {
			this.$store.dispatch('setCurrentMaintenance', item);

			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-edit-maintenance' });
			} else if (!this.isSuperAdmin) {
				this.$router.push({ path: '/edit-maintenance' });
			} else {
				this.$toaster.warning('Invalid Source Screen');
			}
		},
		repairMaintenance(item) {
			this.$store.dispatch('setCurrentMaintenance', item);

			if (item.status !== 'In-Repair') {
				this.$store.commit('SET_CURR_MAINTENANCE', item);
				EventBus.$emit('onRepairSelMaintenance', item);
				this.$bvModal.show('proceed-to-repair-maintenance');
			} else {
				if (this.isSuperAdmin) {
					this.$router.push({ path: '/admin/admin-repair-maintenance' });
				} else if (!this.isSuperAdmin) {
					this.$router.push({ path: '/repair-maintenance' });
				} else {
					this.$toaster.warning('Invalid Source Screen');
				}
			}
		},
		updateSelMaintenance(item) {
			this.$store.commit('SET_CURR_MAINTENANCE', item);
			EventBus.$emit('onUpdateSelMaintenance', item);
		},
		isVisible(status) {
			const allowedStatuses = [status[0], status[1]];
			return allowedStatuses.includes(this.status) && !this.isViewer;
		},
		isAllowedToCancel(maintenance, status) {
			const allowedStatuses = [status[0], status[1]];
			const hasNoRepairedBatches = maintenance.repairedBatches.length === 0;
			return allowedStatuses.includes(this.status) && !this.isViewer && hasNoRepairedBatches;
		},

	},
};
</script>