<template>
	<b-modal id="print-maintenance-summary" :title="title" size="xl" ok-title="Download" ref="modal" @ok="handleDownload"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-container ref="maintenanceSummary" v-show="maintenanceObj !== null">
			<div class="maintenance-summary">
				<b-row class="my-2">
					<b-col sm="6">
						<div class="upper-header">Maintenance Report</div>
						<div class="maintenance-id">
							Maintenance ID: {{ maintenanceObj.maintenanceId }}
						</div>
						<div class="upper-header">
							{{ getFormattedDate(maintenanceObj.dateCreated) }}
						</div>
					</b-col>
					<b-col sm="6" align="right">
						<img v-img-orientation-changer src="img/logo.png" class="img-logo" alt="Ayun! Logo" />
					</b-col>
				</b-row>

				<b-row class="my-2">
					<b-col sm="6" class="highlight-section">
						<b-row no-gutters>
							<b-col sm="5" class="section-header">
								PRIMARY INFORMATION
							</b-col>
							<b-col sm="7">
								<hr />
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> COMPANY: </b-col>
							<b-col sm="7" class="form-value">
								{{ maintenanceObj.area ? maintenanceObj.area.company : '-' }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field">
								STORAGE LOCATION:
							</b-col>
							<b-col sm="7" class="form-value">
								{{ maintenanceObj.area ? maintenanceObj.area.storageLocation : '-' }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> ASSET TYPE: </b-col>
							<b-col sm="7" class="form-value">
								{{ maintenanceObj.assetType }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> CREATED BY: </b-col>
							<b-col sm="7" class="form-value">
								{{ maintenanceObj.createdBy }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> STATUS: </b-col>
							<b-col sm="7" class="form-value">
								{{ maintenanceObj.status }}
							</b-col>
						</b-row>
					</b-col>
					<b-col sm="6">
						<b-row no-gutters>
							<b-col sm="4" class="section-header">
								SCAN TO VIEW IN APP
							</b-col>
							<b-col sm="8">
								<hr />
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col class="ml-5" sm="7">
								<qr-code :size="100" :text="maintenanceObj.maintenanceId" />
							</b-col>
						</b-row>
						<b-row></b-row>
					</b-col>
				</b-row>

				<b-row class="my-4">
					<b-col sm="6">
						<b-row no-gutters class="mb-1">
							<b-col sm="5" class="section-header">
								SUPPORTING DOCUMENTS
							</b-col>
							<b-col sm="7">
								<hr />
							</b-col>
						</b-row>

						<b-row v-for="(document, id) in maintenanceObj.documents" :key="id" :class="{ 'mt-2': id !== 0 }">
							<b-col sm="4" class="form-field">
								DOCUMENT {{ id + 1 }}:
							</b-col>
							<b-col sm="7" class="form-value">
								{{ getMediaName(document) }}
							</b-col>
						</b-row>
					</b-col>

					<b-col sm="6">
						<b-row no-gutters class="mb-1">
							<b-col sm="4" class="section-header">
								CLASSIFICATION
							</b-col>
							<b-col sm="8">
								<hr />
							</b-col>
						</b-row>

						<b-row v-for="(condition, id) in maintenanceObj.conditions" :key="id" :class="{ 'mt-2': id !== 0 }">
							<b-col sm="4" class="form-field">
								{{ condition.condition.toUpperCase() }}:
							</b-col>
							<b-col sm="7" class="form-value">
								{{ condition.quantity }}
							</b-col>
						</b-row>
					</b-col>
				</b-row>

				<b-row class="my-4">
					<b-col sm="6">
						<b-row no-gutters class="mb-1">
							<b-col sm="5" class="section-header">
								REPAIRED BATCHES
							</b-col>
							<b-col sm="7">
								<hr />
							</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="4" class="form-field"> REPAIRED: </b-col>
							<b-col sm="7" class="form-value">
								{{ totalRepaired }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> CHECKED: </b-col>
							<b-col sm="7" class="form-value">
								{{ totalChecked }}
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> ENDORSED: </b-col>
							<b-col sm="7" class="form-value">
								{{ totalEndorsed }}
							</b-col>
						</b-row>
					</b-col>

					<b-col sm="6">
						<b-row no-gutters class="mb-1">
							<b-col sm="4" class="section-header">
								BATCH DETAILS
							</b-col>
							<b-col sm="8">
								<hr />
							</b-col>
						</b-row>

						<b-row class="mt-0">
							<b-col sm="4" class="form-field">
								TOTAL BATCHES:
							</b-col>
							<b-col sm="7" class="form-value">
								{{ batchesArr.length }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field">
								IN PRODUCTION:
							</b-col>
							<b-col sm="7" class="form-value">
								{{ getBatchCountWith('In Production') }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> IN QC: </b-col>
							<b-col sm="7" class="form-value">
								{{ getBatchCountWith('In QC') }}
							</b-col>
						</b-row>

						<b-row class="mt-2">
							<b-col sm="4" class="form-field"> IN WAREHOUSE: </b-col>
							<b-col sm="7" class="form-value">
								{{ getBatchCountWith('In Warehouse') }}
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<b-row class="my-2 footer">
				<b-col sm="3" align="left">
					<img src="img/tawitech_logo_white.png" width="30" height="30" alt="TAWItech Logo" />
				</b-col>
				<b-col sm="9" align="right" style="margin-top: 5px">
					Diezmo Rd., Brgy., Pulo, Cabuyao, Laguna, Philippines, 4025
					| (+63 2) 8519 4042 | (+63 49) 543 0598
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// DAO
import maintenanceDAO from '@/database/maintenances'

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import html2canvas from 'html2canvas';

export default {
	name: 'print-maintenance-summary',
	components: {
		Loading,
	},
	data() {
		return {
			maintenanceObj: {},
			batchesArr: [],

			totalQuantity: 0,
			totalRepaired: 0,
			totalChecked: 0,
			totalEndorsed: 0,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Print Maintenance ' + this.maintenanceObj.maintenanceId;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onupdate-maintenance', async (maintenance) => {
			// show loading indicator
			this.isLoading = true;

			this.maintenanceObj = maintenance;
			await this.onInitBatches();

			// hide loading indicator
			this.isLoading = false;
		});
	},
	methods: {

		async onInitBatches() {
			const batchesObj = await maintenanceDAO.getBatches(this.maintenanceObj.id);
			this.batchesArr = Object.values(batchesObj);

			for (const item of this.batchesArr) {

				const classifications = item.classifications;
				for (const classification of classifications) {

					this.totalQuantity += classification.quantity;
					if (item.repaired && this.isNonNullStr(item.repaired.repairedBy))
						this.totalRepaired += (classification.quantity - classification.rejected);

					if (item.checked && this.isNonNullStr(item.checked.checkedBy))
						this.totalChecked = (classification.quantity - classification.rejected);

					if (item.endorsed && this.isNonNullStr(item.endorsed.endorsedBy))
						this.totalEndorsed = (classification.quantity - classification.rejected);
				}
			}
		},

		isNonNullStr(str) {
			return str && str.length !== 0;
		},

		getFormattedDate(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		getMediaName(media) {
			return (media && media.name) ? media.name : '';
		},

		getBatchCountWith(status) {
			return MaintenanceUtil.getBatchCountWith(status, this.batchesArr);
		},

		getFileName() {
			let company = this.maintenanceObj.company;
			let storageLocation = this.maintenanceObj.storageLocation;
			let area = company + '-' + storageLocation;

			let maintenanceId = this.maintenanceObj.maintenanceId;

			return maintenanceId + '_' + area + '.png';
		},
		async handleDownload(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// Show loader
			this.isLoading = true;

			window.scroll(0, 0);

			let filename = this.getFileName();
			let canvas = await html2canvas(this.$refs.maintenanceSummary);

			const link = document.createElement('a');
			link.setAttribute('download', filename);
			link.setAttribute(
				'href',
				canvas
					.toDataURL('image/png')
					.replace('image/png', 'image/octet-stream')
			);
			link.click();

			this.$refs.modal.hide();
			// Hide loader
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onupdate-maintenance');
	},
};
</script>

<style scoped>
.img-logo {
	margin-left: -30px;
	width: 32%;
}

.upper-header {
	opacity: 0.5;
	text-transform: uppercase;
	color: #111118;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
}

.maintenance-id {
	text-transform: uppercase;
	color: #122c91;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	align-items: center;
}

.footer {
	font-size: 12px;
	text-transform: capitalize;
	margin-top: 40px;
	color: white;
	background-color: #122c91;
}

.section-header {
	font-size: 13px;
	margin-top: 11px;
	line-height: 9px;
	color: #122c91;
	font-weight: bold;
	text-transform: uppercase;
	text-align: left !important;
}

.form-field {
	font-size: 13px;
	text-align: right;
	text-transform: uppercase;
	line-height: 15px;
}

.form-value {
	font-size: 13px;
	font-weight: bold;
	text-align: left;
	text-transform: uppercase;
	line-height: 15px;
}

.highlight-section {
	background: #fff6e9;
	padding-bottom: 20px;
	border-radius: 10px;
	border: 1.5px solid #f18f01;
}

.maintenance-summary {
	padding: 1em;
}
</style>
