<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-wrench"></i> Maintenance</b-card-title>
			<b-card-sub-title>Manages the ongoing maintenances for all companies</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>

					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date From" description="Reference to the Date Created">
											<b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date From')" class="help-block">
												{{ errors.first('Date From') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Date To" description="Reference to the Date Created">
											<b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date To')" class="help-block">
												{{ errors.first('Date To') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="allCompanyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Storage Location">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - "
												:options="storageLocationOptions" :reduce="(storage) => storage.value"
												v-model="filterBy.storageLocation">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Status">
											<b-form-select v-model="filterBy.status" :options="statusOptions"
												class="mr-2" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Asset Type">
											<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="allAssetTypesOptions"
												:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">

												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Maintenance ID"
											description="NOTE: Input the exact Maintenance ID to search">
											<b-form-input id="maintenanceId" name="Maintenance ID" type="search"
												class="numFont" v-model="filterBy.maintenanceId" v-validate="{
					required: false,
					regex: /^MS\d{13}$/
				}" placeholder="MSXXXXXXXXXXXXX" autocomplete="off" />
											<span v-show="errors.has('Maintenance ID')" class="help-block">{{
					errors.first('Maintenance ID')
				}}</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown id="maintenance-select-actions" text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item @click="addMaintenance" v-show="!isViewer">
								Add Maintenance
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls"
									:name="fileName + '.xls'">
									Export Maintenances in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv"
									:name="fileName + '.csv'">
									Export Maintenances to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table ref="maintenancesTable" show-empty striped hover :items="items" :fields="fields"
					:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

					<template v-slot:cell(maintenanceID)="row">
						<span class="numFont">
							<strong>{{ row.item.maintenanceID }}</strong>
						</span>
					</template>

					<template v-slot:cell(area)="row">
						{{ row.item.area ? getArea(row.item.area) : '-' }}
					</template>

					<template v-slot:cell(dateUpdated)="row">
						{{ row.item.dateCreated ? getDisplayDateTime(row.item.dateUpdated) : '-' }}
					</template>

					<template v-slot:cell(status)="row">
						<MaintenanceRowStatus :row="row" />
					</template>

					<template v-slot:cell(actions)="row">
						<MaintenanceRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isViewer="isViewer" />
					</template>

					<template slot="row-details" slot-scope="row">
						<MaintenanceDetailsView :row="row" :allUsersObj="allUsersObj" />
					</template>

				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>
		</b-card>

		<!-- Modals here -->
		<ImageViewDialog />
		<CompleteMaintenance />
		<ToRepairMaintenance />
		<CancelMaintenance />
		<PrintMaintenanceSummary />
	</div>
</template>

<script>
// Components
import MaintenanceRowStatus from '@/views/transactions/maintenance/MaintenanceRowStatus';
import MaintenanceRowActions from '@/views/transactions/maintenance/MaintenanceRowActions';
import MaintenanceDetailsView from '@/views/transactions/maintenance/MaintenanceDetailsView';
import CompleteMaintenance from '@/views/transactions/maintenance/CompleteMaintenance';
import ToRepairMaintenance from '@/views/transactions/maintenance/ToRepairMaintenance';
import CancelMaintenance from '@/views/transactions/maintenance/CancelMaintenance';
import ImageViewDialog from '@/views/transactions/common/ImageViewDialog';
import PrintMaintenanceSummary from '@/views/transactions/maintenance/PrintMaintenanceSummary';

// Utils
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';

// API
import maintenanceApi from '@/api/maintenanceApi';
import dispatchApi from '@/api/dispatchApi';

// DAO
import maintenanceDAO from '@/database/maintenances';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'maintenance',
	components: {
		MaintenanceRowStatus,
		MaintenanceRowActions,
		MaintenanceDetailsView,
		CompleteMaintenance,
		ToRepairMaintenance,
		CancelMaintenance,
		ImageViewDialog,
		PrintMaintenanceSummary,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'maintenanceId',
					label: 'Maintenance ID',
					sortable: true,
				},
				{
					key: 'area',
					sortable: true,
				},
				{
					key: 'assetType',
					sortable: true,
				},
				{
					key: 'dateUpdated',
					sortable: true,
				},
				{
					key: 'status',
					class: 'text-center'
				},
				{
                    key: 'actions',
                    thClass: 'text-center'
                }
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			dateFormatOptions: { ...config.dateFormatOptions },

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: { ...config.companyDefaultValue },
				storageLocation: { ...config.companyDefaultValue },
				status: 'Open',
				maintenanceId: '',
				assetType: { ...config.assetTypeDefaultValue },
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				company: { ...config.companyDefaultValue },
				storageLocation: { ...config.companyDefaultValue },
				status: 'Open',
				maintenanceId: '',
				assetType: { ...config.assetTypeDefaultValue },
			},
			prevFilterBy: {},

			allCompanyOptions: [],
			allAssetTypesOptions: [],
			storageLocationOptions: [],
			statusOptions: MaintenanceUtil.getMaintenanceStatus(),

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectedStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allAssetTypesObj: {},
			allMaintenancesObj: {},
			allDispatchesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,

			// Listener
			maintenanceListener: null,
		};
	},
	watch: {
		'filterBy.company': function () {
			this.onChangeCompany();
		}
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */

		exportFields() {
			return {
				'Maintenance ID': 'maintenanceId',
				'Area': 'area',
				'Asset Type': 'assetType',
				'Date Updated': 'dateUpdated',
				'Status': 'status',
				'Date Created': 'dateCreated',
				'Date Cancelled': 'dateCancelled',
				'Description': 'description',
				'Disposed': 'disposedCount',
				'Repaired': 'repaired',
				'Checked': 'checked',
				'Endorsed': 'endorsed',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Maintenance-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	created() {
		// Set Default Company
		if (!this.isSuperAdmin) {
			this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
		}

		// Set Default Date Range
		this.defaultFilterBy.dateFrom = this.dateFrom;
		this.defaultFilterBy.dateTo = this.dateTo;
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isScanner) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				await this.updateParams();
				await this.retrieveData();
				this.resetStorageLocationOptions();

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}	
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveMaintenance', (maintenanceObj) => {
			this.updateTable(maintenanceObj);
		});
	},
	methods: {
		listenerCallback(type, maintenance) {
			if ((!this.allMaintenancesObj[maintenance.id] && type === "added") || type === "modified") {
				this.allMaintenancesObj[maintenance.id] = maintenance;
				this.filterMaintenances(this.allMaintenancesObj);
			}
		},
		async updateParams() {
			// reset filter to default
			this.filterBy = { ...this.defaultFilterBy };

			this.allCompaniesObj = { ...this.$store.getters.companies };
			this.allConnectedCompaniesObj = { ...this.$store.getters.connectedCompanies };
			this.allStorageLocationsObj = { ...this.$store.getters.storageLocations };
			this.allConnectedStorageLocationsObj = { ...this.$store.getters.connectedStorageLocations };
			this.allConnectionsObj = { ...this.$store.getters.connections };
			this.allUsersObj = { ...this.$store.getters.users };
			this.allAssetTypesObj = { ...this.$store.getters.assetTypes };

			await this.retrieveDispatch();

			// Create Source and Destination Company Options
			if (this.isSuperAdmin) {
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.storageLocationOptions = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
			} else {
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allConnectedCompaniesObj);
				this.storageLocationOptions = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);

				this.allCompaniesObj = { ...this.allCompaniesObj, ...this.allConnectedCompaniesObj };
				this.allStorageLocationsObj = { ...this.allStorageLocationsObj, ...this.allConnectedStorageLocationsObj };
			}

			// Create Asset Type Options
			this.allAssetTypesOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);
		},
		async retrieveDispatch() {
			let filterBy = {
				destinationCompany: !this.isSuperAdmin ? this.loggedUserCompany : {},
				status: 'Received',
			}

			const { data } = await dispatchApi.getDispatches(
				filterBy,
				config.view.ADMIN,
				this.loggedUser.id
			);
			this.allDispatchesObj = data.dispatches;
			this.$store.dispatch('updateAllDispatches', this.allDispatchesObj);
		},
		updateTable(maintenanceObj) {
			if (_.isEmpty(maintenanceObj)) {
				return;
			}
			this.allMaintenancesObj[maintenanceObj.id] = maintenanceObj;
			this.filterMaintenances(this.allMaintenancesObj);
		},

		resetStorageLocationOptions() {
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value:  { ...config.storageLocationDefaultValue },
				text: ' - Please select - ',
			});

			this.filterBy.storageLocation =  { ...config.storageLocationDefaultValue };
		},
		onChangeCompany() {
			// reset storage fields
			this.resetStorageLocationOptions();

			if (this.filterBy.company !== config.companyDefaultValue) {
				// retrieve the storage locations
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationsByConnection(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.filterBy.company.id,
						this.filterBy.company.id
					);

				// pre-select the single option value
				if (this.storageLocationOptions.length == 2) {
					this.filterBy.storageLocation = this.storageLocationOptions[1].value;
				}
			}
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		validateFilter() {
			let isValid = true;

			if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
				this.$toaster.warning('Date From and Date To are required.');
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			} else if (!ValidationUtil.isAlphaNumeric(this.filterBy.maintenanceId)) {
				this.$toaster.warning('Invalid Maintenance ID. Please enter a valid Maintenance ID');
				isValid = false;
			}

			return isValid;
		},

		async onFilterRequest() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				await this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;
				filter.source = 'Maintenance';
				filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				if (this.isSuperAdmin) {
					const { data } = await maintenanceApi.getMaintenances(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allMaintenancesObj = data.maintenances;
				} else {
					const { data } = await maintenanceApi.getMaintenances(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allMaintenancesObj = data.maintenances;
				}

				this.filterMaintenances(this.allMaintenancesObj);

				// Update listener
				this.maintenanceListener = maintenanceDAO.getMaintenanceListener(filter, this.listenerCallback);
				
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		filterMaintenances(allMaintenancesObj){
			let filteredObjs = { ...allMaintenancesObj };

			let fromTimestamp = DateUtil.startDateTimeStamp(new Date(this.filterBy.dateFrom));
			let toTimestamp = DateUtil.endDateTimeStamp(new Date(this.filterBy.dateTo));

			_.forEach(filteredObjs, (maintenance, id) => {
				if (maintenance.dateCreated < fromTimestamp && maintenance.dateCreated > toTimestamp) {
					delete filteredObjs[id];
				}

				let companyId = this.filterBy.company.id;		
				if (!_.isEmpty(companyId) && companyId !== maintenance.companyId) {
					delete filteredObjs[id];
				}

				let storageLocationId = this.filterBy.storageLocation.id;
				if (!_.isEmpty(storageLocationId) && storageLocationId !== maintenance.storageLocationId) {
					delete filteredObjs[id];
				}

				let status = this.filterBy.status;
				if (status && status.length > 0 && status !== maintenance.status) {
					delete filteredObjs[id];
				}

				let maintenanceId = this.filterBy.maintenanceId;
				if (maintenanceId && maintenanceId.length > 0 && maintenanceId !== maintenance.maintenanceId) {
					delete filteredObjs[id];
				}

				let assetType = this.filterBy.assetType;
				if (assetType && assetType.id && assetType.id !== maintenance.assetTypeId) {
					delete filteredObjs[id];
				}
			});

			this.processMaintenances(filteredObjs);
		},
		processMaintenances(maintenances) {
			this.items = Object.values(maintenances);
			this.items = _.sortBy(this.items, ['dateCreated']);
			this.items.reverse();

			this.totalRows = this.items.length;
			this.$store.dispatch('setAllMaintenances', this.allMaintenancesObj);

			// remove show details
			_.forEach(this.items, item => {
				delete item._showDetails;
			});

			// refresh table
			if (this.$refs.maintenancesTable) {
				this.$refs.maintenancesTable.refresh();
			}
		},

		addMaintenance() {
			this.updateMaintenanceParam();
			this.$store.dispatch('setCurrentMaintenance', {});

			// redirect to Add Maintenance page
			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-add-maintenance' });
			} else {
				this.$router.push({ path: '/add-maintenance' });
			}
		},

		updateMaintenanceParam() {
			let params = {
				allCompaniesObj: this.allCompaniesObj,
				allStorageLocationsObj: this.allStorageLocationsObj,
				allConnectionsObj: this.allConnectionsObj,
				allUsersObj: this.allUsersObj,
				allAssetTypesObj: this.allAssetTypesObj,
				allMaintenancesObj: this.allMaintenancesObj,
				allDispatchesObj: this.allDispatchesObj
			};

			this.$store.dispatch('setMaintenanceParams', params);
		},

		// UTILS
		getDisplayDateTime(date) {
			if (date) {
				return DateUtil.getFormattedDateWithTime(date);
			}
			return "-";
		},
		getArea(area) {
			return MaintenanceUtil.getCompanyLocationDisplay(area)
		}
	},

	beforeUnmount() {
		if (this.maintenanceListener != null) {
			// Stop listening to changes
			this.maintenanceListener();
		}
	},

	beforeDestroy() {
		EventBus.$off('onCloseSaveMaintenance');
	},
};
</script>
